<template>
  <div class="search-languages-block">
    <custom-inline-search ref='selector'
                          :placeholder="placeholder"
                          @search-input-changed="searchInputChanged"
                          :with-result-slots="true"
                          :min-input-length="0"
                          :show-caret="true"
                          :full-width-content="true"
                          results-max-height="256px"
                          :initial-data="currentLocaleName"
                          :config="searchInputConfigs"
                          :search-results="languages">

      <template v-slot:heading-info>
        <template v-if="isLanguagesLoaded">
          <dynamic-image classes="country-flag"
                         :width="18"
                         :height="14"
                         :size="32"
                         :country-code="localeCountryCode(currentLanguage)?.countryCode"/>
          {{ currentLocaleName }}
        </template>
        <template v-else>
          <default-spinner/>
          <span class="languages-loader">
            {{ loaderText }}
          </span>
        </template>
      </template>

      <template v-slot:results>
        <template
          v-if="preparedLanguages?.popular?.length > 0 || preparedLanguages?.other?.length > 0">
          <div class="results-group"
               v-if="preparedLanguages?.popular?.length > 0">
            <div class="group-title">Popular in {{ currentCountryName }}</div>
            <div v-for="result in preparedLanguages?.popular"
                 @click="languageClicked(result.code, result.name)"
                 class="result-item">
              <template v-if="localeCountryCode(result?.code)?.countryCode">
                <dynamic-image classes="country-flag"
                               :width="18"
                               :height="14"
                               :size="32"
                               :country-code="localeCountryCode(result?.code)?.countryCode"/>
              </template>
              <span class="item-name">{{ result?.name }}</span>
            </div>
          </div>

          <div class="results-group"
               v-if="preparedLanguages?.other?.length > 0">
            <div class="group-title">Other</div>
            <div v-for="result in preparedLanguages?.other"
                 @click="languageClicked(result.code, result.name)"
                 class="result-item">
              <template v-if="localeCountryCode(result?.code)?.countryCode">
                <dynamic-image classes="country-flag"
                               :width="18"
                               :height="14"
                               :size="32"
                               :country-code="localeCountryCode(result?.code)?.countryCode"/>
              </template>
              <span class="item-name">{{ result?.name }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="results-group">
            <div class="text">No data found</div>
          </div>
        </template>
      </template>
    </custom-inline-search>
  </div>
</template>

<script>
import DefaultSpinner from "@/components/UI/DefaultSpinner/index.vue";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";

export default {
  name: 'LanguageSelector',
  components: {
    DefaultSpinner,
    CustomInlineSearch
  },
  emits: ['language-changed'],
  props: {
    languages: {
      type: Object,
      default: {},
    },
    currentLanguage: {
      type: String,
      default: {},
    },
    currentCountryName: {
      type: String,
      default: '',
    },
    isLanguagesLoaded: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Choose language',
    },
    loaderText: {
      type: String,
      default: 'Loading languages...',
    },
  },
  data() {
    return {
      searchInputValue: '',
      searchInputConfigs: {
        border: 'none',
        boxShadow: '0 8px 38px 0 rgba(184, 186, 229, 0.16)',
        height: '36px',
        width: '256px',
      },
    };
  },
  methods: {
    searchInputChanged(value) {
      this.searchInputValue = value;
      this.expandAddedModel = false;
    },
    localeCountryCode(code) {
      return getLocaleCountryCode(code);
    },
    languageClicked(code, name) {
      this.searchInputValue = '';
      this.$refs.selector.isOpened = false;
      this.$emit('language-changed', code, name);
    }
  },
  computed: {
    preparedLanguages() {
      const tempArray = {...this.languages};
      const loweredInput = this.searchInputValue?.toLowerCase();
      return {
        popular: tempArray?.popular?.filter(language => language?.name?.toLowerCase()?.includes(loweredInput)),
        other: tempArray?.other?.filter(language => language?.name?.toLowerCase()?.includes(loweredInput)),
      }
    },
    currentLocaleName() {
      return this.localeCountryCode(this.currentLanguage)?.localeName
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
