<template>
  <div class="autosuggest-page">
    <div class="new-container">
      <h1 class="page-title mobile-hidden">
        {{ title }}
      </h1>
    </div>

    <div class="autosuggest-page-content">
      <div class="add-entity-block-header mobile-visible">
        <h1 class="page-title">{{ title }}</h1>
      </div>


      <div class="new-container"
           v-if="showFirstScreen">
        <div class="empty-results-block">
          <div class="image">
            <img src="@/assets/images/find-app-illustration.svg"
                 width="228"
                 height="220"
                 alt="Autosuggest illustration"/>
          </div>
          <div class="empty-results-text">
            <div class="text-header">Keyword Suggest Tool</div>
            <div class="text-subheader">Get new keyword ideas for your app with App Store auto-suggestions.</div>
          </div>

          <div class="page-intro-search-block">
            <form>
              <div class="search-input-wrap">
                <label>
                  <input type="text"
                         class="search-input"
                         @input.prevent
                         @keydown.prevent.enter="inputSearchHandler"
                         placeholder="Search keywords..."
                         ref="searchInput"
                         autofocus
                         v-model="currentKeyword">
                  <svg-icon class="search-icon"
                            @click="inputSearchHandler"
                            icon="search-solid"/>
                </label>

                <language-selector :current-language="currentCountriesLocaleSelected"
                                   :current-country-name="currentKeywordCountryName"
                                   :languages="countriesLocalesObject"
                                   :is-languages-loaded="isLanguagesLoaded"
                                   @language-changed="countriesLocalesClicked"
                />
              </div>
            </form>
          </div>

        </div>
      </div>

      <template v-else>
        <template v-if="!searchResultsLoading">
          <div class="live-sesarch-results">
            <div class="new-container">
              <div class="search-block">
                <div class="search-options-block">
                  <div class="search-input-wrap">
                    <form>
                      <label>
                        <input type="text"
                               class="search-input"
                               @input.prevent
                               @keydown.prevent.enter="inputSearchHandler"
                               placeholder="Search for my apps"
                               ref="searchInput"
                               autofocus
                               v-model="currentKeyword">
                      </label>
                    </form>
                    <svg-icon class="search-icon"
                              @click="inputSearchHandler"
                              icon="search-solid"/>
                  </div>

                  <language-selector :current-language="currentCountriesLocaleSelected"
                                     :current-country-name="currentKeywordCountryName"
                                     :languages="countriesLocalesObject"
                                     :is-languages-loaded="isLanguagesLoaded"
                                     @language-changed="countriesLocalesClicked"
                  />
                </div>

                <div class="checkboxes-block checkboxes-block-wrap">
                  <order-radaso-button class="order-radaso-button-wrap" :pre-selected="['keywords-research']" />

                  <div class="checkbox-trigger">
                    <div class="checkboxes-block type-2">
                      <input type="checkbox"
                             name="show-added"
                             id="show-added"
                             v-model="showAddedModel"
                             @change="showAddedChangeHandler">
                      <label for="show-added">Show added</label>
                    </div>
                  </div>
                  <div class="checkbox-trigger">
                    <div class="checkboxes-block type-2">
                      <input type="checkbox"
                             name="expand-all"
                             id="expand-all"
                             v-model="expandAllModel"
                             @click="clickExpandAll"
                      >
                      <label for="expand-all">Expand all</label>
                    </div>
                  </div>
                </div>
                <div class="add-all-mobile desktop-hidden">
                  <template v-if="showAddAllButtonBlock">
                    <div class="buttons-block">
                      <template v-if="showAddAllButton">
                        <div class="add-all-block"
                             @click="addAllKeywordsHandler">Add All Keywords
                        </div>
                        <div v-if="userSubscription?.plan_name === 'Free'"
                             @click="showUpgradeModal"
                             class="download-button">
                          <svg-icon icon="download"/>
                        </div>
                        <a v-else
                           :href="fetchDownloadData()"
                           class="download-button"
                           download>
                          <svg-icon icon="download"/>
                        </a>
                      </template>
                      <template v-else>
                        <div class="add-all-block"
                             @click="deleteAllKeywordsHandler">Delete All Keywords
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>

              <div class="options-and-filter-block">
                <div class="options-block">
                  <custom-dropdown v-if="false" :min-width="'36px'"
                                   :dropdown-width="'295px'"
                                   :with-icon="false"
                                   :with-shadow="true"
                                   :dropdown-align="'left'"
                                   :no-border="true"
                                   :classes="'options-btn'">
                    <template v-slot:header>
                      <div class="text">Filters</div>
                      <span class="icon">
                     <svg-icon icon="plus"/>
                    </span>
                    </template>
                    <template v-slot:content>
                      <div class="select-options">
                        <div class="option active">Popularity</div>
                        <div class="option">Text Contain</div>
                        <div class="option">Text Does not Contain</div>
                      </div>
                    </template>
                  </custom-dropdown>
                  <div class="filter-item-wrap">
                    <div class="filter-item-wrap">
                      <range-input :min-width="'168px'"
                                   :initial-data="rangeFiltersGeneral.sap"
                                   @filter-applied="filterApplied($event, 'sap')">Popularity
                      </range-input>
                    </div>
                  </div>
                  <translate-tool :active="showTranslation"
                                  @translate-clicked="translateClickHandler"
                  />
                </div>

                <template v-if="showAddAllButtonBlock">
                  <div class="buttons-block">
                    <template v-if="showAddAllButton">
                      <div class="add-all-block desktop-visible"
                           @click="addAllKeywordsHandler">Add All Keywords
                      </div>
                      <div v-if="userSubscription?.plan_name === 'Free'"
                           @click="showUpgradeModal"
                           class="download-button">
                        <svg-icon icon="download"/>
                      </div>
                      <a v-else
                         :href="fetchDownloadData()"
                         class="download-button desktop-visible"
                         download>
                        <svg-icon icon="download"/>
                      </a>
                    </template>
                    <template v-else>
                      <div class="add-all-block desktop-visible"
                           @click="deleteAllKeywordsHandler">Delete All Keywords
                      </div>
                      <div v-if="userSubscription?.plan_name === 'Free'"
                           @click="showUpgradeModal"
                           class="download-button">
                        <svg-icon icon="download"/>
                      </div>
                      <a v-else
                         :href="fetchDownloadData()"
                         class="download-button"
                         download>
                        <svg-icon icon="download"/>
                      </a>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="buttons-block">
                    <div v-if="userSubscription?.plan_name === 'Free'"
                         @click="showUpgradeModal"
                         class="download-button">
                      <svg-icon icon="download"/>
                    </div>
                    <a v-else
                       :href="fetchDownloadData()"
                       class="download-button"
                       download>
                      <svg-icon icon="download"/>
                    </a>
                  </div>
                </template>
              </div>

              <div class="filters-block-mobile-wrap">
                <filters-dropdown
                    @dropdown-closed="dropdownClosed">
                  <template v-slot:accordion-body>
                    <div class="filters-block-mobile">
                      <div class="filter-item-wrap">
                        <range-input :min-width="'168px'"
                                     :close-dropdown-with-reset-values="closeDropdownWithResetValues"
                                     :initial-data="rangeFiltersGeneral.sap"
                                     @filter-applied="filterApplied($event, 'sap')">Popularity
                        </range-input>
                      </div>
                    </div>
                  </template>
                </filters-dropdown>
              </div>

              <div class="suggest-tables-grid"
                   v-if="autosuggestComputed.autosuggestResults?.length > 0 || JSON.stringify(autosuggestComputed.autosuggestAlphabetResults) !== '{}'">
                <div class="autosuggest-table-wrap"
                     v-if="autosuggestComputed.autosuggestResults?.length > 0">
                  <div class="autosuggest-table">

                    <suggestion-data-table :headers="headers"
                                           :items="autosuggestComputed.autosuggestResults"
                                           :rows-per-page-items="[50, 100, 250, 500, 1000]"
                                           :fixed-width="true"
                                           :show-actions="false"
                                           :pagination="pageMeta"
                                           :sorting-meta="sortingMeta"
                                           :items-per-page="+pageMeta.per_page"
                                           :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}"
                                           :show-more-outside-trigger="expandAllTrigger"
                                           :reset-to-default="resetTablesNum"
                                           @show-more-clicked="showMoreClicked"
                                           @update-sort="updateSort">

                      <template v-slot:header-add-all>
                        <template v-if="!addAllButtonInactive('autosuggestResults')">
                          <custom-tooltip
                              width="90px"
                              :straight="true"
                              direction-horizontal="left"
                              text-align="center"
                              @click="addAllClickHandler(lastSearchedKeyword, 'autosuggestResults')">
                            <template v-slot:slotTrigger>
                              <svg-icon icon="plus"
                                        class="add-all-block-icon"/>
                            </template>
                            <template v-slot:slotContent>
                              Add all
                            </template>
                          </custom-tooltip>
                        </template>
                        <template v-else>
                          <svg-icon icon="plus"
                                    class="add-all-block-icon inactive"/>
                        </template>
                      </template>

                      <template v-slot:header-title>{{ lastSearchedKeyword }}</template>

                      <template v-slot:items="{item}">
                        <td v-for="headerItem in headers"
                            :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '']">
                          <div class="inner-content">
                            <template v-if="headerItem?.value !== 'tools'">
                              <template v-for="(tdItem, tdIndex) in item"
                                        :key="tdIndex">
                                <template v-if="tdIndex === headerItem.value">
                                  <template v-if="headerItem?.value === 'suggest'">
                                    <div class="block-content"
                                         :style="{maxWidth: headerItem.textWidth}">
                                      <span>{{ tdItem }}</span>
                                      <span class="translated-block" v-if="item.translation">
                                        {{ item.translation }}
                                      </span>
                                    </div>
                                  </template>

                                  <template v-else-if="headerItem?.value === 'position'">
                                    {{ tdItem }}
                                  </template>

                                  <template v-else-if="headerItem?.value === 'selected'">
                                    <template v-if="!item.isLoading">
                                      <div class="add-delete-icon"
                                           :class="{'included': item.frozen}">

                                        <template v-if="item.frozen">
                                          <custom-tooltip
                                              width="120px"
                                              :straight="true"
                                              direction-horizontal="left"
                                              text-align="center">
                                            <template v-slot:slotTrigger>
                                              <svg-icon class="added-icon" icon="check"/>
                                            </template>
                                            <template v-slot:slotContent>
                                              Keyword added
                                            </template>
                                          </custom-tooltip>
                                        </template>
                                        <template v-else>
                                          <template v-if="item.selected">
                                            <custom-tooltip
                                                width="140px"
                                                :straight="true"
                                                direction-horizontal="left"
                                                text-align="center"
                                                @click="removeSingleKeywordHandler(item, 'autosuggestResults')">
                                              <template v-slot:slotTrigger>
                                                <svg-icon class="close-icon" icon="close"/>
                                              </template>
                                              <template v-slot:slotContent>
                                                Remove keyword
                                              </template>
                                            </custom-tooltip>
                                          </template>
                                          <template v-else>
                                            <custom-tooltip
                                                width="110px"
                                                :straight="true"
                                                direction-horizontal="left"
                                                text-align="center"
                                                @click="addSingleKeywordHandler([item], 'autosuggestResults')">
                                              <template v-slot:slotTrigger>
                                                <svg-icon icon="plus"/>
                                              </template>
                                              <template v-slot:slotContent>
                                                Add keyword
                                              </template>
                                            </custom-tooltip>
                                          </template>
                                        </template>
                                      </div>
                                    </template>

                                    <template v-else>
                                      <div class="preloader-wrap">
                                        <preloader-table-small></preloader-table-small>
                                      </div>
                                    </template>

                                  </template>

                                  <template v-else>
                                    <template v-if="tdItem === null || tdItem === undefined || tdItem?.length === 0">
                                      <img src="@/assets/images/Account-Keywords/soon.svg"
                                           width="16"
                                           height="16"
                                           alt="Data will be available soon">
                                    </template>
                                    <template v-else>
                                      <div class="block-content"
                                           :style="{maxWidth: headerItem.textWidth}">{{ tdItem }}
                                      </div>
                                    </template>
                                  </template>

                                </template>
                              </template>
                            </template>
                          </div>
                        </td>
                      </template>
                    </suggestion-data-table>
                  </div>
                </div>

                <template v-for="(tableData, idx, index) in autosuggestComputed.autosuggestAlphabetResults"
                          :key="idx">

                  <template v-if="index === 5 && showUpgradeBlock">
                    <div class="upgrade-plan-block-wrap">
                      <div class="upgrade-plan-block">
                        <div class="text"
                             @click="showUpgradeModal">
                          ⚡️ <span>Upgrade Plan to See All Autosuggests</span> ⚡️
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="autosuggest-table-wrap"
                       v-if="tableData?.length > 0">

                    <div class="autosuggest-table"
                         :class="{blured: index > 4 && !userLimits?.suggestions_allowed?.is_active }">
                      <suggestion-data-table :headers="headers"
                                             :items="tableData"
                                             :rows-per-page-items="[50, 100, 250, 500, 1000]"
                                             :fixed-width="true"
                                             :show-actions="false"
                                             :pagination="pageMeta"
                                             :sorting-meta="sortingMeta"
                                             :items-per-page="+pageMeta.per_page"
                                             :sorting-keys="{direction: 'sort_direction', sorting: 'sort_by', paginate: 'per_page'}"
                                             :show-more-outside-trigger="expandAllTrigger"
                                             :reset-to-default="resetTablesNum"
                                             @show-more-clicked="showMoreClicked"
                                             @update-sort="updateSort">

                        <template v-slot:header-add-all>
                          <template v-if="!addAllButtonInactive('autosuggestAlphabetResults', idx)">
                            <template v-if="index > 4 && !userLimits?.suggestions_allowed?.is_active">
                              <svg-icon icon="plus"
                                        class="add-all-block-icon"/>
                            </template>
                            <template v-else>
                              <custom-tooltip
                                  width="90px"
                                  :straight="true"
                                  direction-horizontal="left"
                                  text-align="center"
                                  @click="addAllClickHandler(idx, 'autosuggestAlphabetResults', idx)">
                                <template v-slot:slotTrigger>
                                  <svg-icon icon="plus"
                                            class="add-all-block-icon"/>
                                </template>
                                <template v-slot:slotContent>
                                  Add all
                                </template>
                              </custom-tooltip>
                            </template>
                          </template>
                          <template v-else>
                            <svg-icon icon="plus"
                                      class="add-all-block-icon inactive"/>
                          </template>
                        </template>

                        <template v-slot:header-title>{{ idx }}</template>

                        <template v-slot:items="{item}">
                          <td v-for="headerItem in headers"
                              :style="{width: headerItem.width}"
                              :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem.isSticky ? 'is-sticky' : '', headerItem.stickyClass ? headerItem.stickyClass : '']">
                            <div class="inner-content">
                              <template v-if="headerItem?.value !== 'tools'">
                                <template v-for="(tdItem, tdIndex) in item"
                                          :key="tdIndex">

                                  <template v-if="tdIndex === headerItem.value">
                                    <template v-if="headerItem?.value === 'suggest'">
                                      <div class="block-content"
                                           :style="{maxWidth: headerItem.textWidth}">
                                        <span class="keyword">{{ tdItem }}</span>
                                        <span class="translated-block" v-if="item.translation">
                                          {{ item.translation }}
                                        </span>
                                      </div>
                                    </template>

                                    <template v-else-if="headerItem?.value === 'sap'">
                                      {{ tdItem }}
                                    </template>

                                    <template v-else-if="headerItem?.value === 'selected'">
                                      <template v-if="!item.isLoading">
                                        <div class="add-delete-icon"
                                             :class="{'included': item.frozen}">

                                          <template v-if="item.frozen">
                                            <custom-tooltip
                                                width="120px"
                                                :straight="true"
                                                direction-horizontal="left"
                                                text-align="center">
                                              <template v-slot:slotTrigger>
                                                <svg-icon class="added-icon" icon="check"/>
                                              </template>
                                              <template v-slot:slotContent>
                                                Keyword added
                                              </template>
                                            </custom-tooltip>
                                          </template>
                                          <template v-else>
                                            <template v-if="item.selected">
                                              <custom-tooltip
                                                  width="140px"
                                                  :straight="true"
                                                  direction-horizontal="left"
                                                  text-align="center"
                                                  @click="removeSingleKeywordHandler(item, 'autosuggestAlphabetResults', idx)">
                                                <template v-slot:slotTrigger>
                                                  <svg-icon class="close-icon" icon="close"/>
                                                </template>
                                                <template v-slot:slotContent>
                                                  Remove keyword
                                                </template>
                                              </custom-tooltip>
                                            </template>
                                            <template v-else>

                                              <template v-if="index > 4 && !userLimits?.suggestions_allowed?.is_active">
                                                <svg-icon icon="plus"/>
                                              </template>
                                              <template v-else>
                                                <custom-tooltip
                                                    width="110px"
                                                    :straight="true"
                                                    direction-horizontal="left"
                                                    text-align="center"
                                                    @click="addSingleKeywordHandler([item], 'autosuggestAlphabetResults', idx)">
                                                  <template v-slot:slotTrigger>
                                                    <svg-icon icon="plus"/>
                                                  </template>
                                                  <template v-slot:slotContent>
                                                    Add keyword
                                                  </template>
                                                </custom-tooltip>
                                              </template>
                                            </template>
                                          </template>
                                        </div>
                                      </template>

                                      <template v-else>
                                        <div class="preloader-wrap">
                                          <preloader-table-small></preloader-table-small>
                                        </div>
                                      </template>

                                    </template>

                                    <template v-else>
                                      <template v-if="tdItem === null || tdItem === undefined || tdItem?.length === 0">
                                        <img src="@/assets/images/Account-Keywords/soon.svg"
                                             width="16"
                                             height="16"
                                             alt="Data will be available soon">
                                      </template>
                                      <template v-else>
                                        <div class="block-content"
                                             :style="{maxWidth: headerItem.textWidth}">{{ tdItem }}
                                        </div>
                                      </template>
                                    </template>

                                  </template>

                                </template>
                              </template>
                            </div>
                          </td>
                        </template>
                      </suggestion-data-table>
                    </div>
                  </div>
                </template>
              </div>

              <div class="no-data-block"
                   v-if="showNoResultsBlock">
                <div class="content">
                  <span class="emoji">😢</span>
                  <span class="text">No keywords that match your criteria.</span>
                </div>
              </div>

<!--              <the-improvement-block/>-->
            </div>
          </div>
        </template>
        <template v-else>
          <progress-indicator :loaded="searchResultsLoading">{{ $route.meta.loaderText }}</progress-indicator>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TheHeader from '@/components/TheHeader/index';
import CustomInlineSearch from '@/components/Forms/CustomInlineSearch/index';
import CustomRangeInput from "@/components/Forms/CustomRangeInput/index";
import CustomChips from "@/components/Forms/CustomChips";
import {httpRequest} from "@/api";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import SvgIcon from "@/components/UI/SvgIcon";
import {keywordsAutosuggestTableConfig} from "@/configs/page-tables-configs/keywords-autosuggest";
import PreloaderTableSmall from '@/components/UI/PreloaderTableSmall/index';
import SuggestionTable from "@/views/SearchTerms/KeywordsAutosuggest/components/SuggestionTable";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index";
import {getCookie, setCookie, deleteCookie} from "@/utils/utils";
import {getLocaleCountryCode} from "@/configs/locales-flags/index";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import LanguageSelector from "@/views/SearchTerms/KeywordsAutosuggest/components/LanguageSelector";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: 'KeywordsAutosuggest',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.title = to?.meta?.pageTitle ?? '';
      vm.loaderText = to?.meta?.loaderText ?? '';
    })
  },
  components: {
    OrderRadasoButton,
    LanguageSelector,
    TranslateTool,
    SvgIcon,
    'the-header': TheHeader,
    'custom-inline-search': CustomInlineSearch,
    'custom-chips': CustomChips,
    'range-input': CustomRangeInput,
    'preloader-table-small': PreloaderTableSmall,
    'custom-dropdown': CustomDropdown,
    'suggestion-data-table': SuggestionTable,
    'progress-indicator': ProgressIndicator,
    'filters-dropdown': FiltersDropdown,
  },
  data() {
    return {
      title: '',
      loaderText: '',
      autosuggest: {
        autosuggestResults: [],
        autosuggestAlphabetResults: {},
      },
      showAddAllButtonBlock: true,
      rangeFiltersGeneral: {
        sap: {
          min: null,
          max: null,
        },
      },
      currentKeyword: '',
      lastSearchedKeyword: '',
      showTableChartModal: false,
      showPopularChartLoader: false,
      liveSearchDevice: 'iphone',
      liveSearchPage: 1,
      currentKeywordCountryCode: 'US',
      currentKeywordCountryId: null,
      currentKeywordCountryName: null,
      searchResultsLoading: false,
      showFirstScreen: true,
      showAddedModel: true,
      expandAllTrigger: 0,
      expandAllModel: false,
      showAddAllButton: true,
      device: 'iphone',
      sort: 'sap',
      order: 'asc',
      addedSuggestsIds: [],
      showMoreArray: [],
      resetTablesNum: 0,
      generalCounter: 0,
      addedCounter: 0,
      notAddedCounter: 0,
      countriesLocalesObject: {},
      currentCountriesLocaleSelected: 'en',
      closeDropdownWithResetValues: 0,
      showTranslation: false,
      isLanguagesLoaded: false,
    }
  },
  methods: {
    async inputSearchHandler() {
      if (this.currentKeyword.length === 0) {
        return;
      }

      this.showFirstScreen = false;
      this.searchResultsLoading = true;
      this.showAddAllButton = true;
      this.showTranslation = false;

      await this.sendPromisesChunk();
      this.$store.dispatch('keywordsTracking/SET_SORTING_META', {sort_by: 'sap', sort_direction: 'desc'});
      // this.showFirstScreen = false;
      this.searchResultsLoading = false;

      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
      const days = 0.007;
      // const days = 7;
      setCookie('keywordsSuggestInput', this.currentKeyword, cookieDomain, days);
      this.lastSearchedKeyword = this.currentKeyword;
      this.rangeFiltersGeneral.sap.min = null;
      this.rangeFiltersGeneral.sap.max = null;
      this.showMoreArray = [];
      this.expandAllTrigger = 0;
    },
    countSelectedKeywords() {
      let selectedCounter = 0;
      for (const key in this.autosuggestComputed.autosuggestResults) {
        const suggestItem = this.autosuggestComputed.autosuggestResults[key];
        if (suggestItem?.selected && !suggestItem?.is_tracked) {
          selectedCounter++;
        }
      }

      for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
        const topLevelItemData = this.autosuggestComputed.autosuggestAlphabetResults[key];

        for (const subKey in topLevelItemData) {
          if (topLevelItemData[subKey]?.selected && !topLevelItemData[subKey]?.is_tracked) {
            selectedCounter++;
          }
        }
      }

      if (selectedCounter < this.notAddedCounter) {
        this.showAddAllButton = true;
      } else {
        this.showAddAllButton = false;
      }
    },
    async addAllKeywordsHandler(addedKeywords) {
      const currentAppId = getCookie('currentAppId');
      let addedKeywordsArray = addedKeywords ?? [];
      let formData = new FormData();
      let tempSuggestsArray = [];
      let keywordsFormDataArray = new Set();

      if (!currentAppId) {
        return;
      }

      formData.append("country_code", this.currentKeywordCountryCode);
      formData.append("application_id", currentAppId);

      if (addedKeywords?.isTrusted) {
        addedKeywordsArray = this.autosuggestComputed.autosuggestResults.filter(keyword => !keyword.selected && !keyword.is_tracked);
      }
      tempSuggestsArray = [...this.autosuggestComputed.autosuggestResults];

      for (const key in tempSuggestsArray) {
        addedKeywordsArray.forEach(keyword => {
          if (keyword?.suggest === tempSuggestsArray[key]?.suggest) {
            tempSuggestsArray[key].isLoading = true;
            keywordsFormDataArray.add(tempSuggestsArray[key].suggest);
          }
        });
      }

      this.autosuggestComputed.autosuggestResults = [...tempSuggestsArray];

      for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
        const alphabetArray = this.autosuggestComputed.autosuggestAlphabetResults;
        tempSuggestsArray = [...alphabetArray[key]];
        if (addedKeywords?.isTrusted) {
          addedKeywordsArray = alphabetArray[key].filter(keyword => !keyword.selected && !keyword.is_tracked);
        }

        for (const subKey in tempSuggestsArray) {
          addedKeywordsArray.forEach(keyword => {
            if (keyword?.suggest === tempSuggestsArray[subKey]?.suggest) {
              tempSuggestsArray[subKey].isLoading = true;
              if (!tempSuggestsArray[subKey].suggest?.includes('****')) {
                keywordsFormDataArray.add(tempSuggestsArray[subKey].suggest);
              }
            }
          });
        }

        this.autosuggestComputed.autosuggestAlphabetResults[key] = [...tempSuggestsArray];
      }

      let counter = 0;
      keywordsFormDataArray.forEach(keyword => {
        formData.append(`keywords[${counter}]`, keyword);
        counter++;
      });

      let result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.ALL_KEYWORDS, formData);
      // let result = [];

      if (result?.message === 'success') {
        if (addedKeywords?.isTrusted) {
          addedKeywordsArray = this.autosuggestComputed.autosuggestResults.filter(keyword => !keyword.selected && !keyword.is_tracked);
        }

        tempSuggestsArray = [...this.autosuggestComputed.autosuggestResults];

        for (const key in tempSuggestsArray) {
          addedKeywordsArray.forEach(keyword => {
            if (keyword?.suggest === tempSuggestsArray[key]?.suggest) {
              tempSuggestsArray[key].isLoading = false;
              tempSuggestsArray[key].selected = true;
              tempSuggestsArray[key].tracked_id = result?.saved_keywords_ids[keyword.suggest];
              if (result?.saved_keywords_ids[keyword.suggest] && !this.addedSuggestsIds.includes(result?.saved_keywords_ids[keyword.suggest])) {
                this.addedSuggestsIds.push(result?.saved_keywords_ids[keyword.suggest]);
              }
            }
          });
        }

        this.autosuggestComputed.autosuggestResults = [...tempSuggestsArray];

        for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
          const alphabetArray = this.autosuggestComputed.autosuggestAlphabetResults;
          tempSuggestsArray = [...alphabetArray[key]];
          if (addedKeywords?.isTrusted) {
            addedKeywordsArray = alphabetArray[key].filter(keyword => !keyword.selected && !keyword.is_tracked);
          }

          for (const subKey in tempSuggestsArray) {
            addedKeywordsArray.forEach(keyword => {
              if (keyword?.suggest === tempSuggestsArray[subKey]?.suggest) {
                tempSuggestsArray[subKey].isLoading = false;
                tempSuggestsArray[subKey].selected = true;
                tempSuggestsArray[subKey].tracked_id = result?.saved_keywords_ids[keyword.suggest];
                if (result?.saved_keywords_ids[keyword.suggest] && !this.addedSuggestsIds.includes(result?.saved_keywords_ids[keyword.suggest])) {
                  this.addedSuggestsIds.push(result?.saved_keywords_ids[keyword.suggest]);
                }
              }
            });
          }

          this.autosuggestComputed.autosuggestAlphabetResults[key] = [...tempSuggestsArray];
        }

        // this.showAddAllButton = false;
        this.$store.dispatch('UPDATE_USER_LIMITS');
        await this.fetchDataCounts();
        this.countSelectedKeywords();
      }
    },
    async deleteAllKeywordsHandler() {
      let tempSuggestsArray = [...this.autosuggestComputed.autosuggestResults];

      const data = {
        application_id: +getCookie('currentAppId'),
        keywords: this.addedSuggestsIds,
        country_code: this.currentCountry?.code
      };

      for (const key in tempSuggestsArray) {
        this.addedSuggestsIds.forEach(id => {
          if (id === tempSuggestsArray[key]?.tracked_id) {
            tempSuggestsArray[key].isLoading = true;
          }
        });
      }

      this.autosuggestComputed.autosuggestResults = [...tempSuggestsArray];

      for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
        const alphabetArray = this.autosuggestComputed.autosuggestAlphabetResults;
        tempSuggestsArray = [...alphabetArray[key]];

        for (const subKey in tempSuggestsArray) {
          this.addedSuggestsIds.forEach(id => {
            if (id === tempSuggestsArray[subKey]?.tracked_id) {
              tempSuggestsArray[subKey].isLoading = true;
            }
          });
        }

        this.autosuggestComputed.autosuggestAlphabetResults[key] = [...tempSuggestsArray];
      }

      let result = await httpRequest('DELETE', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.BATCH_DELETE_KEYWORDS, data);

      if (result?.message === 'success') {
        let tempSuggestsArray = [...this.autosuggestComputed.autosuggestResults];

        for (const key in tempSuggestsArray) {
          this.addedSuggestsIds.forEach(id => {
            if (id === tempSuggestsArray[key]?.tracked_id) {
              tempSuggestsArray[key].isLoading = false;
              tempSuggestsArray[key].selected = false;
            }
          });
        }

        this.autosuggestComputed.autosuggestResults = [...tempSuggestsArray];

        for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
          const alphabetArray = this.autosuggestComputed.autosuggestAlphabetResults;
          tempSuggestsArray = [...alphabetArray[key]];

          for (const subKey in tempSuggestsArray) {
            this.addedSuggestsIds.forEach(id => {
              if (id === tempSuggestsArray[subKey]?.tracked_id) {
                tempSuggestsArray[subKey].isLoading = false;
                tempSuggestsArray[subKey].selected = false;
              }
            });
          }

          this.autosuggestComputed.autosuggestAlphabetResults[key] = [...tempSuggestsArray];
        }
      }

      // this.showAddAllButton = true;
      this.addedSuggestsIds = [];
      this.$store.dispatch('UPDATE_USER_LIMITS');
      await this.fetchDataCounts();
      this.countSelectedKeywords();
    },
    async filterApplied(e, filter) {
      this.notAddedCounter = 0;
      this.addedCounter = 0;
      this.generalCounter = 0;

      this.rangeFiltersGeneral[filter].min = e?.min ?? null;
      this.rangeFiltersGeneral[filter].max = e?.max ?? null;
      this.resetTablesNum++;
      // this.showAddAllButton = true;
      this.expandAllTrigger = 0;
      this.showMoreArray = [];
    },
    async updateSort(data) {
      this.searchResultsLoading = true;
      this.$store.dispatch('keywordsTracking/SET_SORTING_META', data);
      this.sort = data.sort_by;
      this.order = data.sort_direction;
      this.searchResultsLoading = false;
    },
    async sendPromisesChunk() {
      if (this.currentKeyword === '') {
        return;
      }

      const currentAppId = getCookie('currentAppId');
      this.lastSearchedKeyword = this.currentKeyword;
      this.searchResultsLoading = true;

      const url = '?country_code=' + this.currentKeywordCountryCode
          + '&keyword=' + this.currentKeyword
          + '&sort=' + this.sort
          + '&order=' + this.order
          + '&app_id=' + currentAppId
          + '&device=' + this.device
          + '&locale=' + this.currentCountriesLocaleSelected;

      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsAutosuggest.KEYWORD_AUTOSUGGEST + url,
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsAutosuggest.KEYWORD_AUTOSUGGEST_ALPHABET + url,
          method: 'GET',
        },
      ];

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
      const keywordsAutosuggest = this.promiseSettledData?.data[0]?.value;
      const keywordsAutosuggestAlphabet = this.promiseSettledData?.data[1]?.value;
      // if (keywordsAutosuggest?.length > 0 && !keywordsAutosuggest?.message && keywordsAutosuggest?.message !== undefined) {
      if (keywordsAutosuggest?.length > 0 && !keywordsAutosuggest?.message) {
        this.autosuggest.autosuggestResults = keywordsAutosuggest.map(item => {
          return {
            ...item,
            selected: false,
            frozen: item.is_tracked,
          }
        });
      } else {
        this.autosuggest.autosuggestResults = [];
      }

      if (keywordsAutosuggestAlphabet !== null && keywordsAutosuggestAlphabet !== undefined && !keywordsAutosuggestAlphabet?.message) {
        this.autosuggest.autosuggestAlphabetResults = {...keywordsAutosuggestAlphabet};

        for (const key in this.autosuggest.autosuggestAlphabetResults) {
          const data = this.autosuggest.autosuggestAlphabetResults[key];
          for (const subKey in data) {
            data[subKey].selected = data[subKey]?.is_tracked;
            data[subKey].frozen = data[subKey]?.is_tracked;
          }
        }
      }
    },
    showAddedChangeHandler() {
      let tempSuggestsArray = this.autosuggest.autosuggestResults.map(keyword => {
        return {
          ...keyword,
          isIncluded: keyword?.is_tracked
        }
      });

      this.autosuggest.autosuggestResults = tempSuggestsArray;

      for (const key in this.autosuggest.autosuggestAlphabetResults) {
        const alphabetArray = this.autosuggest.autosuggestAlphabetResults;
        tempSuggestsArray = alphabetArray[key].map(keyword => {
          return {
            ...keyword,
            isIncluded: keyword?.is_tracked
          }
        });
        this.autosuggest.autosuggestAlphabetResults[key] = tempSuggestsArray;
      }
    },
    async addSingleKeywordHandler(addedKeywordArray) {
      await this.addAllKeywordsHandler(addedKeywordArray);
    },
    async removeSingleKeywordHandler(deletedKeyword) {
      let tempSuggestsArray = [...this.autosuggestComputed.autosuggestResults];
      for (const key in tempSuggestsArray) {
        if (deletedKeyword.suggest === tempSuggestsArray[key].suggest) {
          tempSuggestsArray[key].isLoading = true;
        }
      }

      this.autosuggestComputed.autosuggestResults = [...tempSuggestsArray];

      for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
        const alphabetArray = this.autosuggestComputed.autosuggestAlphabetResults;
        tempSuggestsArray = [...alphabetArray[key]];

        for (const subKey in tempSuggestsArray) {
          if (deletedKeyword.suggest === tempSuggestsArray[subKey].suggest) {
            tempSuggestsArray[subKey].isLoading = true;
          }
        }

        this.autosuggestComputed.autosuggestAlphabetResults[key] = [...tempSuggestsArray];
      }

      //Remove deleted id from array of all added ids
      const deletedIdx = this.addedSuggestsIds.findIndex(index => index === deletedKeyword.tracked_id);
      this.addedSuggestsIds.splice(deletedIdx, 1);

      const data = {application_id: +getCookie('currentAppId'), country_code: this.currentCountry?.code};
      let result = await httpRequest('DELETE', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.DELETE_KEYWORDS + '/' + deletedKeyword.tracked_id, data);

      if (result?.message === 'success') {
        let tempSuggestsArray = [...this.autosuggestComputed.autosuggestResults];
        for (const key in tempSuggestsArray) {
          if (deletedKeyword.suggest === tempSuggestsArray[key].suggest) {
            tempSuggestsArray[key].isLoading = false;
            tempSuggestsArray[key].selected = false;
          }
        }

        this.autosuggestComputed.autosuggestResults = [...tempSuggestsArray];
      }

      for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
        const alphabetArray = this.autosuggestComputed.autosuggestAlphabetResults;
        tempSuggestsArray = [...alphabetArray[key]];

        for (const subKey in tempSuggestsArray) {
          if (deletedKeyword.suggest === tempSuggestsArray[subKey].suggest) {
            tempSuggestsArray[subKey].isLoading = false;
            tempSuggestsArray[subKey].selected = false;
          }
        }

        this.autosuggestComputed.autosuggestAlphabetResults[key] = [...tempSuggestsArray];
      }

      if (this.addedSuggestsIds?.length === 0) {
        // this.showAddAllButton = true;
      }

      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.countSelectedKeywords();
    },
    async addAllClickHandler(searchWord, array, keywordBlockIdx) {
      let addedKeywordsArray = [];
      if (!keywordBlockIdx) {
        addedKeywordsArray = this.autosuggestComputed[array].filter(keyword => !keyword.selected && !keyword.is_tracked);
      } else {
        addedKeywordsArray = this.autosuggestComputed[array][keywordBlockIdx].filter(keyword => !keyword.selected && !keyword.is_tracked);
      }
      await this.addAllKeywordsHandler(addedKeywordsArray);
    },
    addAllButtonInactive(array, keywordBlockIdx) {
      let amount = 0;
      if (keywordBlockIdx) {
        amount = this.autosuggestComputed[array][keywordBlockIdx]?.filter(keyword => keyword?.selected || keyword?.is_tracked)?.length;
        return amount === this.autosuggestComputed[array][keywordBlockIdx]?.length;
      } else {
        amount = this.autosuggestComputed[array]?.filter(keyword => keyword?.selected || keyword?.is_tracked)?.length;
        return amount === this.autosuggestComputed[array]?.length;
      }
    },
    showMoreClicked(event) {
      const findIdx = this.showMoreArray.findIndex(item => item.hash === event.hash);

      if (findIdx === -1) {
        this.showMoreArray.push(event);
      } else {
        const tempArr = [...this.showMoreArray];
        tempArr[findIdx] = {...event};
        this.showMoreArray = [...tempArr];
      }

      const suggestResultsLength = this.autosuggestComputed.autosuggestResults.length > 0 ? 1 : 0;
      let alphabetResultsLength = 0;

      for (const key in this.autosuggestComputed.autosuggestAlphabetResults) {
        const wordBlock = this.autosuggestComputed.autosuggestAlphabetResults[key];
        if (wordBlock?.length > 5) {
          alphabetResultsLength++;
        }
      }

      let counter = 0;
      this.showMoreArray.forEach(item => {
        if (item.expanded) {
          counter++;
        } else {
          counter = counter > 0 ? counter-- : 0;
        }
      });
      this.expandAllModel = counter === alphabetResultsLength + suggestResultsLength;
    },
    fetchDownloadData() {
      const currentAppId = getCookie('currentAppId');

      if (currentAppId) {
        let urlQuery = '?country_code=' + this.currentKeywordCountryCode
          + '&locale=' + this.currentCountriesLocaleSelected
          + '&keyword=' + this.currentKeyword
          + '&sort=' + this.sort
          + '&order=' + this.order
          + '&app_id=' + currentAppId
          + '&device=' + this.device;

        if (this.rangeFiltersGeneral.sap.min || this.rangeFiltersGeneral.sap.max) {
          urlQuery += '&min_sap=' + this.rangeFiltersGeneral.sap.min
            + '&max_sap=' + this.rangeFiltersGeneral.sap.max;
        }

        return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_AUTOSUGGESTS + urlQuery;
      }
    },
    async fetchCountryLocales() {
      this.isLanguagesLoaded = false;
      const localesData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LANGUAGES + `/${this.currentApp?.store?.key}/${this.currentCountry.code}`);
      this.countriesLocalesObject = {
        default: localesData?.default,
        popular: this.setLocales(localesData?.popular),
        other: this.setLocales(localesData?.other),
      };
      this.isLanguagesLoaded = true;
      this.currentCountriesLocaleSelected = this.countriesLocalesObject?.default;
    },
    setLocales(obj) {
      let localesArr = [];
      for (const key in obj) {
        localesArr.push({
          code: key,
          name: obj[key],
        })
      }
      return localesArr;
    },
    setDefaultLocale() {
      this.currentCountriesLocaleSelected = {
        code: this.countriesLocalesObject?.default,
        name: this.localeCountryCode(this.countriesLocalesObject?.default)?.localeName,
      };
    },
    countriesLocalesInputChanged(value) {
      this.countryLocaleSearchInputValue = value;
      this.expandAllTrigger = 0;
    },
    async countriesLocalesClicked(code, locale) {
      if (!this.userLimits?.suggestions_allowed?.is_active) {
        this.showUpgradeModal();
        return;
      }

      this.expandAllTrigger = 0;

      this.currentCountriesLocaleSelected = code;
      this.searchResultsLoading = true;
      await this.sendPromisesChunk();
      this.searchResultsLoading = false;
    },
    dropdownClosed() {
      this.closeDropdownWithResetValues++;
    },
    showUpgradeModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    clickExpandAll() {
      if (!this.expandAllModel) {
        this.expandAllTrigger++;
      } else {
        this.expandAllTrigger = 0;
      }
    },
    sortSuggests(items) {
      if (this.sort === 'suggest') {
        if (this.order === 'asc') {
          return items.sort();
        } else {
          return items.reverse();
        }
      } else {
        if (this.order === 'asc') {
          return items.sort((a, b) => a[this.sort] - b[this.sort])
        } else {
          return items.sort((a, b) => b[this.sort] - a[this.sort]);
        }
      }
    },
    clearTranslations() {
      this.autosuggest.autosuggestResults.forEach((item) => {
        item.translation = null;
      });
      Object.keys(this.autosuggest.autosuggestAlphabetResults).forEach((key) => {
        this.autosuggest.autosuggestAlphabetResults[key].forEach((item) => {
          item.translation = null;
        })
      });
    },
    async translateClickHandler() {
      this.showTranslation = !this.showTranslation;

      if (!this.showTranslation) {
        this.clearTranslations();
      } else {
        let keywords = [];
        this.autosuggest.autosuggestResults.forEach((item) => {
          keywords.push(item.suggest);
        });
        Object.keys(this.autosuggest.autosuggestAlphabetResults).forEach((key) => {
          this.autosuggest.autosuggestAlphabetResults[key].forEach((item) => {
            keywords.push(item.suggest);
          })
        });

        let translatesByKeyword = await translates.translateKeywords(this, this.currentApp?.store?.key, keywords);

        this.autosuggest.autosuggestResults.forEach((item) => {
          item.translation = translatesByKeyword[item.suggest] ?? null;
        });
        Object.keys(this.autosuggest.autosuggestAlphabetResults).forEach((key) => {
          this.autosuggest.autosuggestAlphabetResults[key].forEach((item) => {
            item.translation = translatesByKeyword[item.suggest] ?? null;
          })
        });
      }
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    let keywordParam = urlParams.get('keyword');
    if (keywordParam) {
      this.currentKeyword = keywordParam;
      this.inputSearchHandler();
    } else if (getCookie('keywordsSuggestInput')) {
      this.currentKeyword = getCookie('keywordsSuggestInput');
    }
  },
  async mounted() {
    this.currentKeywordCountryCode = this.currentCountry?.code;
    this.currentKeywordCountryId = this.currentCountry?.id;
    this.currentKeywordCountryName = this.currentCountry?.name;
    this.device = this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile';

    await this.fetchCountryLocales();

    this.searchResultsLoading = true;
    if (this.currentKeyword) {
      this.showFirstScreen = false;

      this.lastSearchedKeyword = this.currentKeyword;
      this.currentKeywordCountryCode = this.currentCountry?.code;
      this.currentKeywordCountryId = this.currentCountry?.id;
      this.currentKeywordCountryName = this.currentCountry?.name;
      await this.sendPromisesChunk();

      this.updateSort({sort_direction: 'desc', sort_by: 'sap'});

      this.$store.dispatch('keywordsTracking/SET_SORTING_META', {sort_by: 'sap', sort_direction: 'desc'});
      this.searchResultsLoading = false;
    }

    if (this.$refs.searchInput) {
      this.$refs.searchInput.focus();
    }
  },
  computed: {
    headers() {
      return keywordsAutosuggestTableConfig.headers
    },
    ...mapGetters([
      'userLimits',
      'isMobile',
      'pageMeta',
      'currentApp',
      'promiseSettledData',
      'currentCountry',
      'currentCountryManual',
      'userSubscription',
    ]),
    ...mapGetters('keywordsTracking', [
      'sortingMeta',
    ]),
    autosuggestComputed() {
      const singleSuggestsResults = [...this.autosuggest.autosuggestResults];
      const alphabetSuggestsResults = {...this.autosuggest.autosuggestAlphabetResults};

      this.notAddedCounter = 0;
      this.addedCounter = 0;
      this.generalCounter = 0;

      let autosuggestSingle = singleSuggestsResults.filter(item => {
        if (this.rangeFiltersGeneral.sap.max === null
            || (item?.sap >= this.rangeFiltersGeneral.sap.min && item?.sap <= this.rangeFiltersGeneral.sap.max)) {
          this.generalCounter++;

          if (!item.is_tracked) {
            this.notAddedCounter++;
          } else {
            this.addedCounter++;
          }

          if (!this.showAddedModel) {
            return item.isIncluded !== true;
          }
          return item;
        }
      });
      autosuggestSingle = this.sortSuggests(autosuggestSingle);

      let autosuggestAlphabet = {};

      for (const key in alphabetSuggestsResults) {
        const subItemData = alphabetSuggestsResults[key].filter(item => {
          if (this.rangeFiltersGeneral.sap.max === null
              || (item?.sap >= this.rangeFiltersGeneral.sap.min && item?.sap <= this.rangeFiltersGeneral.sap.max)) {
            this.generalCounter++;
            if (!item.is_tracked) {
              this.notAddedCounter++;
            } else {
              this.addedCounter++;
            }

            if (!this.showAddedModel) {
              return item.isIncluded !== true;
            }
            return item;
          }
        });

        if (subItemData?.length > 0) {
          autosuggestAlphabet[key] = this.sortSuggests(subItemData);
        }
      }

      this.showAddAllButtonBlock = this.addedCounter !== this.generalCounter;

      return {
        autosuggestResults: autosuggestSingle,
        autosuggestAlphabetResults: autosuggestAlphabet,
      };
    },
    showNoResultsBlock() {
      return this.autosuggestComputed.autosuggestResults?.length === 0 && JSON.stringify(this.autosuggestComputed.autosuggestAlphabetResults) === '{}' && !this.searchResultsLoading;
    },
    showUpgradeBlock() {
      const notEmptyItems = Object.values(this.autosuggestComputed?.autosuggestAlphabetResults)?.filter(item => item?.length > 0);
      return notEmptyItems?.length > 5 && !this.userLimits?.suggestions_allowed?.is_active;
    },
  },
  watch: {
    async $route(to, from) {
      this.title = to?.meta?.pageTitle ?? '';
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.currentKeywordCountryCode = newVal?.code;
        this.currentKeywordCountryId = newVal?.id;
        this.currentKeywordCountryName = newVal?.name;
        await this.fetchCountryLocales();

        if (getCookie('keywordsSuggestInput')) {
          this.currentKeyword = getCookie('keywordsSuggestInput');
          this.searchResultsLoading = true;
          this.showAddAllButton = true;
          await this.sendPromisesChunk();
          this.searchResultsLoading = false;
        }
        this.expandAllTrigger = 0;
        this.$store.dispatch('keywordsTracking/SET_SORTING_META', {sort_by: 'sap', sort_direction: 'desc'});
        this.updateSort({sort_direction: 'desc', sort_by: 'sap'});
      }
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        this.device = this.currentApp?.store?.key === 'APP_STORE' ? 'iphone' : 'mobile';
        await this.fetchCountryLocales();

        if (getCookie('keywordsSuggestInput')) {
          this.currentKeyword = '';
          this.showFirstScreen = true;
          this.autosuggest.autosuggestResults = [];
          this.autosuggest.autosuggestAlphabetResults = {};
          this.showAddAllButton = true;
          deleteCookie('keywordsSuggestInput');

          this.currentKeywordCountryCode = this.currentCountry?.code;
          this.currentKeywordCountryId = this.currentCountry?.id;
          this.currentKeywordCountryName = this.currentCountry?.name;
          this.expandAllTrigger = 0;
          this.$store.dispatch('keywordsTracking/SET_SORTING_META', {sort_by: 'sap', sort_direction: 'desc'});
          this.updateSort({sort_direction: 'desc', sort_by: 'sap'});
        }
      }
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>